import request from '@/utils/request'
const api = {
 	// 获取列表
  push_messages(params) {
    return request({
        url: '/api/push_messages/',
        method: 'get',
        params
    })
  },
  // 修改消息已读状态
  change_isread(data) {
    return request({
        url: '/api/change_isread/',
        method: 'post',
        data
    })
  },
	// 是否有未读消息
  check_message_queue(params) {
    return request({
        url: '/api/check_message_queue/',
        method: 'get',
        params
    })
  },
  // 仪器故障告警详情
  warning_details(params) {
    return request({
        url: '/api/warning_details/',
        method: 'get',
        params
    })
  },
}

export default api