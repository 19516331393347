import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { getToken } from '@/utils/auth';
import store from '../store'
import { Form } from 'element-ui';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login', // 重定向
    component: () => import('../views/Login/Login.vue'),
  },

  // 修改密码
  {
    path: '/changePassword',
    name: 'changePassword',

    component: () => import('../views/changePassword/changePassword.vue'),
  },
  // 消息
  {
    path: '/messages',
    name: 'messages',

    component: () => import('../views/messages/messages.vue'),
  },
   // 告警详情H5页面
   {
    path: '/warnH5',
    name: 'warnH5',

    component: () => import('../views/messages/warnH5.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login/Login.vue'),
  },
  // {
  //   path: '/demo',
  //   name: 'demo',
  //   component: () => import('../views/bigPage/demo.vue'),
  // }
]
const router = new VueRouter({
  routes,
  mode: 'history',
})
router.beforeEach((to, from, next) => {
  let toPage = {
    name: to.name,
    path: to.path,
    query: to.query,
  }
  sessionStorage.setItem('toPage', JSON.stringify(toPage))
  if (to.meta.isCache == true) {
    store.commit('cache', { name: from.name, type: 1 })
    next()
  } else {
    store.commit('cache', { name: to.name, type: 0 })
    next()
  }
})
router.afterEach((to,from) => {
  // localStorage.setItem('oldPage', JSON.stringify({name:to.name,query:to.query}))
  // 从Indoor到Indoor强制刷新一次
  if(to.name == 'Indoor'&&from.name == 'Indoor'){
    location.reload() 
  }
 
})
// 路由跳转前守卫 按住alt按键则打开新页面
router.beforeEach((to, from, next) => {
  let a = false;
  document.onkeydown = e => {
    if(e.keyCode == 18) {
      a = true
    }
  }
  setTimeout(() => {
    if(a){
      window.open(to.fullPath,'_blank');
    }else{
      next();
    }
  }, 100);

 })


export default router
