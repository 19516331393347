import router from '@/router/index.js'
import Home from '../views/Home.vue'


// 其他主页面
const HomeList =  [

  // 云质控页面暂时以此种方式添加
  // { 
  //   path: '/IndoorNew',
  //   name: 'IndoorNew',
  //   meta: {
  //     title: '室内质控',
  //     isBreadcrumb: true,
  //     isCache: false,
  //   },
  //   component: () => import('../views/CloudControlNew/Indoor/index.vue'),
  // },
  // { 
  //   path: '/BetweenNew',
  //   name: 'BetweenNew',
  //   meta: {
  //     title: '室间一致性评价',
  //     isBreadcrumb: true,
  //     isCache: false,
  //   },
  //   component: () => import('../views/CloudControlNew/Between/index.vue'),
  // },

 { 
    path: '/insFile',
    name: 'insFile',
    meta: {
      title: '仪器数据上传',
      isBreadcrumb: true,
      isCache: false,
    },
    component: () => import('../views/Other/insFile.vue'),
  },
  { 
    path: '/newpanel',
    name: 'newpanel',
    meta: {
      title: '质控面板',
      isBreadcrumb: true,
      isCache: false,
    },
    component: () => import('../views/CloudControl/Panel/index.vue'),
  },
  { 
    path: '/eGFR',
    name: 'eGFR',
    meta: {
      title: 'eGFR快速搜索',
      isBreadcrumb: true,
      isCache: false,
    },
    component: () => import('../views/eGFR/eGFR.vue'),
  },
  { 
    path: '/styleStandard',
    name: 'styleStandard',
    meta: {
      title: '仪器数据上传',
      isBreadcrumb: true,
      isCache: false,
    },
    component: () => import('../views/Other/styleStandard.vue'),
  },
  // { 
  //   path: '/dataBoard',
  //   name: 'dataBoard',
  //   meta: {
  //     title: '最新统计',
  //     isBreadcrumb: true,
  //     isCache: false,
  //   },
  //   component: () => import('../views/Statistics/dataBoard.vue'),
  // },
  // { 
  //   path: '/setPerformance',
  //   name: 'setPerformance',
  //   meta: {
  //     title: '业绩设置',
  //     isBreadcrumb: true,
  //     isCache: false,
  //   },
  //   component: () => import('../views/Other/detailPage/setPerformance.vue'),
  // },
  // { 
  //   path: '/performance',
  //   name: 'performance',
  //   meta: {
  //     title: '业绩目标',
  //     isBreadcrumb: true,
  //     isCache: false,
  //   },
  //   component: () => import('../views/Other/performance.vue'),
  // },
  {
    path: '/SearchPage',
    name: 'SearchPage',
    meta: {
      title: '搜索',
      isBreadcrumb: true,
      isCache: false,
    },
    component: () => import('../views/SearchPage/index.vue'),
  },
  // 发货单
  {
    path: 'dispatchs-detail',
    name: 'DispatchsDetaisl',
    meta: {
      title: '发货单',
      isCache: true,
    },
    component: () => import('../views/SearchPage/detailPage/DispatchsDetaisl.vue'),
  },
  // 订单
  {
    path: 'orders-detail',
    name: 'OrdersDetail',
    meta: {
      title: '订单',
      isCache: true,
    },
    component: () => import('../views/SearchPage/detailPage/OrdersDetail.vue'),
  },
  // 失控详情
  {
    path: 'cycle_qc_data_list-detail',
    name: 'ControlDetail',
    meta: {
      title: '失控',
      isCache: true,
    },
    component: () => import('../views/SearchPage/detailPage/ControlDetail.vue'),
  },
  // 产品试剂
  {
    path: 'products-detail',
    name: 'ProductsDetail',
    meta: {
      title: '产品试剂',
      isCache: true,
    },
    component: () => import('../views/SearchPage/detailPage/ProductReagent.vue'),
  },
  // 产品平台
  {
    path: 'apparatus-detail',
    name: 'ApparatusDetail',
    meta: {
      title: '产品平台',
      isCache: true,
    },
    component: () => import('../views/SearchPage/detailPage/ApparatusDetail.vue'),
  },
  // 仪器
  {
    path: 'instruments-detail',
    name: 'InstrumentsDetail',
    meta: {
      title: '仪器',
      isCache: true,
    },
    component: () => import('../views/SearchPage/detailPage/InstrumentsDetail.vue'),
  },
  // 试剂
  {
    path: 'kits-detail',
    name: 'KitsDetail',
    meta: {
      title: '试剂',
      isCache: true,
    },
    component: () => import('../views/SearchPage/detailPage/KitsDetail.vue'),
  },
  // 质检单
  {
    path: 'reports-detail',
    name: 'ReportsDetail',
    meta: {
      title: '质检单',
      isCache: true,
    },
    component: () => import('../views/SearchPage/detailPage/ReportsDetail.vue'),
  },
  // 质控
  {
    path: 'qcs-detail',
    name: 'QcsDetail',
    meta: {
      title: '质控',
      isCache: true,
    },
    component: () => import('../views/SearchPage/detailPage/QcsDetail.vue'),
  },
  // 保养单
  {
    path: 'maintaintask-detail',
    name: 'MaintaintaskDetail',
    meta: {
      title: '保养单',
      isCache: true,
    },
    component: () => import('../views/SearchPage/detailPage/MaintaintaskDetail.vue'),
  },
  // 维修单
  {
    path: 'repairtask-detail',
    name: 'RepairtaskDetail',
    meta: {
      title: '维修单',
      isCache: true,
    },
    component: () => import('../views/SearchPage/detailPage/RepairtaskDetail.vue'),
  },
  // 装机单
  {
    path: 'installtask-detail',
    name: 'InstalltaskDetail',
    meta: {
      title: '装机单',
      isCache: true,
    },
    component: () => import('../views/SearchPage/detailPage/InstalltaskDetail.vue'),
  },
  // 日志
  {
    path: 'diarys-detail',
    name: 'DiarysDetail',
    meta: {
      title: '日志',
      isCache: true,
    },
    component: () => import('../views/SearchPage/detailPage/DiarysDetail.vue'),
  },
  // 物品申请
  {
    path: 'articleapplis-detail',
    name: 'ArticleapplisDetail',
    meta: {
      title: '物品申请',
      isCache: true,
    },
    component: () => import('../views/SearchPage/detailPage/ArticleapplisDetail.vue'),
  },
  // 样品检测
  {
    path: 'samplechecks-detail',
    name: 'SamplechecksDetail',
    meta: {
      title: '样品检测',
      isCache: true,
    },
    component: () => import('../views/SearchPage/detailPage/SamplechecksDetail.vue'),
  },
  // 定标
  {
    path: 'calibrations-detail',
    name: 'CalibrationsDetail',
    meta: {
      title: '定标',
      isCache: true,
    },
    component: () => import('../views/SearchPage/detailPage/CalibrationsDetail.vue'),
  },
  // 告警详情
  {
    path: 'recordwarnings-detail',
    name: 'RecordwarningsDetail',
    meta: {
      title: '告警',
      isCache: true,
    },
    component: () => import('../views/SearchPage/detailPage/RecordwarningsDetail.vue'),
  },
]

const menuData = [
  // 首页
  { 
    permission_flag: 'Shouye',
    parent: null,
    menu: {
      priority: 1,
      name: '首页',
      icon: require('@/assets/img/home.png'),
      img: require('@/assets/img/apply.png'),
      path: '/index',
      children: [],
    },
    route: [{
      path: '/index',
      name: 'Shouye',
      component: () => import('../views/Shouye/Shouye.vue'),
    }],
  },
  // eGFR
  // { 
  //   permission_flag: 'eGFR',
  //   parent: 'Home',
  //   menu: {
  //     priority: 3,
  //     name: 'eGFR',
  //     icon: require('@/assets/img/seachtb.png'),
  //     img: require('@/assets/img/journal.png'),
  //     path: '/eGFR',
  //     children: [],
  //   },
  //   route: [{
  //     path: '/eGFR',
  //     name: 'eGFR',
  //     component: () => import('../views/eGFR/eGFR.vue'),
  //   }],
  // },
  // 管理
  {
    permission_flag: 'Manage',
    parent: 'Home',
    menu: {
      priority: 10,
      name: '管理',
      icon: require('@/assets/img/qttb.png'),
      img: require('@/assets/img/rest.png'),
      children: [
          {
              name: '用户管理',
              path: '/userManage',
          },
          {
              name: '菜单管理',
              path: '/menuManage',
          },
          {
              name: '角色管理',
              path: '/roleManage',
          },
      ],
    },
    route:[ {
      path: 'userManage',
      name: 'userManage',
      meta: {
        title: '用户管理',
        isBreadcrumb: true,
        isCache: false,
      },
      component: () => import('../views/manage/userManage.vue'),
    },
    // 菜单管理
    {
      path: 'menuManage',
      name: 'menuManage',
      meta: {
        title: '菜单管理',
        isBreadcrumb: true,
        isCache: false,
      },
      component: () => import('../views/manage/menuManage.vue'),
    },
    // 角色管理
    {
      path: 'roleManage',
      name: 'roleManage',
      meta: {
        title: '角色管理',
        isBreadcrumb: true,
        isCache: false,
      },
      component: () => import('../views/manage/roleManage.vue'),
    }],
  },
  // 其他
  { 
    permission_flag: 'Other',
    parent: 'Home',
    menu: {
      priority: 11,
      name: '其他',
      icon: require('@/assets/img/qttb.png'),
      img: require('@/assets/img/rest.png'),
      children: [
        {
          permission_flag: "OrderDelivery",
          name: '订单发货',
          path: '/otherOrderDelivery',
        },
        {
          permission_flag: "UploadQualityTesting",
          name: '质检单上传',
          path: '/uploadQualityTesting',
        },
        {
          permission_flag: "AppVersion",
          name: 'app版本管理',
          path: '/appVersion',
        },
        {
          permission_flag: "ForcedDelivery",
          name: '强制发货',
          path: '/forcedDelivery',
        },
        {
          permission_flag: "Check",
          name: '盘点',
          path: '/check',
        },
        {
          permission_flag: "IndustryDynamicsDelivery",
          name: '行业动态',
          path: '/industryDynamicsDelivery',
        },
        {
          permission_flag: "Performance",
          name: '业绩目标',
          path: '/performance',
        },
        {
          permission_flag: "ApproveArticle",
          name: '审批物品申请',
          path: '/ApproveArticle',
        },
   
      ],
    },
    route:[
      {
        permission_flag: "OrderDelivery",
        routeList: [
          {
            path: 'otherOrderDelivery',
            name: 'otherOrderDelivery',
            meta: {
              title: '订单发货',
              isBreadcrumb: true,
              isCache: false,
            },
            component: () => import('../views/Other/otherOrderDelivery.vue'),
          },
          // 订单发货-详情
          {
            path: 'orderDeliveryDetail',
            name: 'orderDeliveryDetail',
            meta: {
              title: '订单发货详情',
              isCache: true,
            },
            component: () => import('../views/Other/detailPage/orderDeliveryDetail.vue'),
          },
        ],
      },
      {
        permission_flag: "Performance",
        routeList: [
          {
            path: 'performance',
            name: 'performance',
            meta: {
              title: '业绩目标',
              isBreadcrumb: true,
              isCache: false,
            },
            component: () => import('../views/Other/performance.vue'),
          },
          // 订单发货-详情
          {
            path: 'setPerformance',
            name: 'setPerformance',
            meta: {
              title: '业绩设置',
              isCache: true,
            },
            component: () => import('../views/Other/detailPage/setPerformance.vue'),
          },
        ],
      },
      {
        permission_flag: "ForcedDelivery",
        routeList: [
          {
            path: 'forcedDelivery',
            name: 'forcedDelivery',
            meta: {
              title: '强制发货',
              isBreadcrumb: true,
              isCache: false,
            },
            component: () => import('../views/Other/forcedDelivery.vue'),
          }
        ]
      },
      {
        permission_flag: "UploadQualityTesting",
        routeList: [
          {
            path: 'uploadQualityTesting',
            name: 'uploadQualityTesting',
            meta: {
              title: '质检单上传',
              isBreadcrumb: true,
              isCache: false,
            },
            component: () => import('../views/Other/uploadQualityTesting.vue'),
          }
        ]
      },
      {
        permission_flag: "AppVersion",
        routeList: [
          {
            path: 'appVersion',
            name: 'appVersion',
            meta: {
              title: 'app版本管理',
              isBreadcrumb: true,
              isCache: false,
            },
            component: () => import('../views/Other/appVersion.vue'),
          }
        ]
      },
      {
        permission_flag: "IndustryDynamicsDelivery",
        routeList: [
          {
            path: 'industryDynamicsDelivery',
            name: 'industryDynamicsDelivery',
            meta: {
              title: '行业动态',
              isBreadcrumb: true,
              isCache: false,
            },
            component: () => import('../views/Other/industryDynamicsDelivery.vue'),
          },
          {
            path: 'industryDynamicsDetail',
            name: 'industryDynamicsDetail',
            meta: {
              title: '动态详情',
              isCache: true,
            },
            component: () => import('../views/Other/detailPage/industryDynamicsDetail.vue'),
          },
        ]
      },
      {
        permission_flag: "ApproveArticle",
        routeList: [
          {
            path: 'ApproveArticle',
            name: 'ApproveArticle',
            meta: {
              title: '审批物品申请',
              isBreadcrumb: true,
            },
            component: () => import('../views/ApproveArticle/index.vue'),
          },
          {
            path: 'ApproveArticleDetail',
            name: 'ApproveArticleDetail',
            meta: {
              title: '物品申请详情',
            },
            component: () => import('../views/ApproveArticle/detailPage/ApproveArticleDetail.vue'),
          }
        ]
      },
    ]
  },
  {
    permission_flag: "CloudControl",
    parent: 'Home',
    menu: {
      priority: 5,
      name: '云质控',
      icon: require('@/assets/img/yunzhikong.png'),
      img: require('@/assets/img/cloud.png'),
      children: [
        {
          permission_flag: "Charge",
          name: '质控面板',
          path: 'Panel',
        },
        {
          permission_flag: "Indoor",
          name: '室内质控',
          path: 'Indoor',
        },
        {
          permission_flag: "Between",
          name: '室间一致性评价',
          path: 'Between',
        },
      ],
    },
    route: [
      {
        permission_flag: "Charge",
        routeList:[{
          path: 'Panel',
          name: 'Panel',
          meta: {
            title: '质控面板',
            isBreadcrumb: true,
          },
          component: () => import('../views/CloudControl/Panel/index.vue'),
        },
        // {
        //   path: 'Today',
        //   name: 'Today',
        //   meta: {
        //     title: '医院',
        //   },
        //   component: () => import('../views/CloudControl/detailPage/Today.vue'),
        // }
      ]
      },
      {
        permission_flag: "Indoor",
        routeList: [{
          path: 'Indoor',
          name: 'Indoor',
          meta: {
            title: '室内质控',
            isBreadcrumb: true,
          },
          component: () => import('../views/CloudControl/Indoor/index.vue'),
        }]
      },
      {
        permission_flag: "Between",
        routeList: [{
          path: 'Between',
          name: 'Between',
          meta: {
            title: '室间一致性评价',
            isBreadcrumb: true,
          },
          component: () => import('../views/CloudControl/Between/index.vue'),
        }]
      }
      // {
      //   permission_flag: "Indoor",
      //   routeList: [{
      //     path: 'Indoor',
      //     name: 'Indoor',
      //     meta: {
      //       title: '室内质控',
      //       isBreadcrumb: true,
      //     },
      //     component: () => import('../views/CloudControl/detailPage/Indoor.vue'),
      //   }]
      // },
     
      // {
      //   permission_flag: "Between",
      //   routeList: [{
      //     path: 'Between',
      //     name: 'Between',
      //     meta: {
      //       title: '室间一致性评价',
      //       isBreadcrumb: true,
      //     },
      //     component: () => import('../views/CloudControl/detailPage/Between.vue'),
      //   }]
      // }
    ]
  },
  {
    permission_flag: "Statistics",
    parent: 'Home',
    menu: {
      priority: 7,
      name: '统计',
      icon: require('@/assets/img/tongji.png'),
      img: require('@/assets/img/statistics.png'),
      children: [
        {
          permission_flag: "DataBoard",
          name: '数据看板',
          path: '/dataBoard',
        },
        {
          permission_flag: "Dosage",
          name: '用量统计',
          path: '/Dosage',
        },
        {
          permission_flag: "Sales",
          name: '销量统计',
          path: '/Sales',
        },
        
        {
          permission_flag: "Maintain",
          name: '维修统计',
          path: '/Maintain',
        },
        {
          permission_flag: "SparePart",
          name: '备件统计',
          path: '/sparePart',
        },
        {
          permission_flag: "Samples",
          name: '地区炎症感染趋势',//样本检测中统计
          path: '/Samples',
        },
      ],
    },
    route: [
      {
        permission_flag: "Dosage",
        routeList: [{
          path: 'Dosage',
          name: 'Dosage',
          meta: {
            title: '用量统计-省份',
            isBreadcrumb: true,
            isCache: false,
          },
          component: () => import('../views/Statistics/Dosage.vue'),
        },
        {
          path: 'TimeSeries',
          name: 'TimeSeries',
          meta: {
            isCache: true,
          },
          component: () => import('../views/Statistics/detailPage/TimeSeries.vue'),
        },
        {
          path: 'Analysis',
          name: 'Analysis',
          meta: {
            isCache: true,
          },
          component: () => import('../views/Statistics/detailPage/Analysis.vue'),
        },
        {
          path: 'DosageHospital',
          name: 'DosageHospital',
          meta: {
            title: '用量统计-医院',
            isBreadcrumb: true,
            isCache: false,
          },
          component: () => import('../views/Statistics/DosageHospital.vue'),
        },
        {
          path: 'DosageHospital-form',
          name: 'DosageHospital-form',
          meta: {
            isCache: true,
          },
          component: () => import('../views/Statistics/detailPage/DosageHospital-form.vue'),
        },
        {
          path: 'DosageHospital-series',
          name: 'DosageHospital-series',
          meta: {
            isCache: true,
          },
          component: () => import('../views/Statistics/detailPage/DosageHospital-series.vue'),
        },
        {
          path: 'Device',
          name: 'Device',
          meta: {
            title: '用量统计-仪器',
            isBreadcrumb: true,
            isCache: false,
          },
          component: () => import('../views/Statistics/Device.vue'),
        },
        {
          path: 'Device-from',
          name: 'Device-from',
          meta: {
            isCache: true,
          },
          component: () => import('../views/Statistics/detailPage/Device-from.vue'),
        },
        {
          path: 'Device-series',
          name: 'Device-series',
          meta: {
            isCache: true,
          },
          component: () => import('../views/Statistics/detailPage/Device-series.vue'),
        },
  
        {
          path: 'National-from',
          name: 'National-from',
          meta: {
            isCache: true,
          },
          component: () => import('../views/Statistics/detailPage/National-from.vue'),
        },
        {
          path: 'National-series',
          name: 'National-series',
          meta: {
            isCache: true,
          },
          component: () => import('../views/Statistics/detailPage/National-series.vue'),
        },]
      },
      {
        permission_flag: "Sales",
        routeList: [{
          path: 'Volume',
          name: 'Volume',
          meta: {
            title: '销量统计-报告',
            isBreadcrumb: true,
            isCache: false,
          },
          component: () => import('../views/Statistics/Volume.vue'),
        },
        {
          path: 'Agency',
          name: 'Agency',
          meta: {
            title: '销量统计-经销商',
            isBreadcrumb: true,
            isCache: false,
          },
          component: () => import('../views/Statistics/Agency.vue'),
        },
        {
          path: 'AgencyComposition',
          name: 'AgencyComposition',
          meta: {
            title: '销量统计-经销商-构成分析',
            isCache: true,
          },
          component: () => import('../views/Statistics/detailPage/AgencyComposition.vue'),
        },
        {
          path: 'AgencyTime',
          name: 'AgencyTime',
          meta: {
            title: '销量统计-经销商-时序分析',
            isCache: true,
          },
          component: () => import('../views/Statistics/detailPage/AgencyTime.vue'),
        },
        {
          path: 'Turnover',
          name: 'Turnover',
          meta: {
            title: '销量统计-日成交量',
            isBreadcrumb: true,
            isCache: false,
          },
          component: () => import('../views/Statistics/Turnover.vue'),
        },
        {
          path: 'Sales',
          name: 'Sales',
          meta: {
            title: '销量统计-省份',
            isBreadcrumb: true,
            isCache: false,
          },
          component: () => import('../views/Statistics/Sales.vue'),
        },
        {
          path: 'GoComposition',
          name: 'GoComposition',
          meta: {
            title: '销量统计-省份-构成分析',
            isCache: true,
          },
          component: () => import('../views/Statistics/detailPage/GoComposition.vue'),
        },
        {
          path: 'GoTime',
          name: 'GoTime',
          meta: {
            title: '销量统计-省份-时序分析',
            isCache: true,
          },
          component: () => import('../views/Statistics/detailPage/GoTime.vue'),
        },]
      },
      {
        permission_flag: "Maintain",
        routeList: [{
          path: 'Maintain',
          name: 'Maintain',
          meta: {
            title: '维修统计-省份',
            isBreadcrumb: true,
            isCache: false,
          },
          component: () => import('../views/Statistics/Maintain.vue'),
        },
        {
          path: 'GoAgency',
          name: 'GoAgency',
          meta: {
            title: '维修统计-医院',
            isBreadcrumb: true,
            isCache: false,
          },
          component: () => import('../views/Statistics/GoAgency.vue'),
        },
        {
          path: 'GoVolume',
          name: 'GoVolume',
          meta: {
            title: '维修统计-仪器',
            isBreadcrumb: true,
            isCache: false,
          },
          component: () => import('../views/Statistics/GoVolume.vue'),
        }]
      },
      {
        permission_flag: "DataBoard",
        routeList: [{
          path: 'dataBoard',
          name: 'dataBoard',
          meta: {
            title: '数据看板',
            isBreadcrumb: true,
            isCache: false,
          },
          component: () => import('../views/Statistics/dataBoard.vue'),
        },
        {
          path: 'saleTarget',
          name: 'saleTarget',
          meta: {
            title: '销售详情',
          },
          component: () => import('../views/Statistics/detailPage/saleTarget.vue'),
        },
      ]
      },
      {
        permission_flag: "SparePart",
        routeList: [{
          path: 'sparePart',
          name: 'sparePart',
          meta: {
            title: '备件统计',
            isBreadcrumb: true,
            isCache: false,
          },
          component: () => import('../views/Statistics/sparePart.vue'),
        },]
      },
      {
        permission_flag: "Samples",
        routeList: [{
          path: 'Samples',
          name: 'Samples',
          meta: {
            title: '地区炎症感染趋势',
            isBreadcrumb: true,
            isCache: false,
          },
          component: () => import('../views/Statistics/Samples.vue'),
        },
        {
          path: 'SamplesRoom',
          name: 'SamplesRoom',
          meta: {
            title: '地区炎症感染趋势-时序图',
            isBreadcrumb: true,
            isCache: true,
          },
          component: () => import('../views/Statistics/detailPage/SamplesRoom.vue'),
        },]
      },
    ]
  }
]
let menuList = []
let searchList = []
let instrumentsStatistic = []
let dosage_nationwide = false
let sale_nationwide = false
let Report = false
let egfr_operation_btn = false
export function getmenuList() {
  menuList.sort((a,b)=> a.priority>b.priority?1:-1)
  return menuList
}
export function getsearchList() {
  return searchList
}
export function getinstrumentsStatistic() {
  return instrumentsStatistic
}
export function getdosage_nationwide() {
  return dosage_nationwide
}
export function getsale_nationwide() {
  return sale_nationwide
}
export function getReport () {
  return Report 
}
export function getEgfrOperationBtn() {
  return egfr_operation_btn 
}

export function addMenu(menuPower,isLogin=false) {
  instrumentsStatistic = []
  let children = []
  menuList = [{
    priority: 2,
    name: '搜索',
    icon: require('@/assets/img/seachtb.png'),
    img: require('@/assets/img/seek.png'),
    path: '/SearchPage',
    children: [],
  }]
  menuPower.forEach(temp => {
    /* 特殊菜单处理 */
    // 搜索菜单
    if(temp.permission_flag == "Search"){
      searchList = temp.children.map(e => {
        return {
          value: e.permission_flag,
          name: e.menu_title,
        }
      })
    }
    // 仪器详情中的统计
    if(searchList.find(e => e.value === 'recordwarnings')){
      instrumentsStatistic.push('recordwarnings') //告警
    }
    if(temp.permission_flag === "Statistics"){
      if( temp.children.find(e => e.permission_flag === 'Maintain')){
        instrumentsStatistic.push('Maintain') //备件
      }
      let DosageTemp = temp.children.find(e => e.permission_flag === 'Dosage')
      if( DosageTemp){
        instrumentsStatistic.push('Dosage') //用量 同时用于首页
        dosage_nationwide = DosageTemp.children.find(e => e.permission_flag === 'dosage_nationwide')?true:false //全国用量
      }
      let SalesTemp = temp.children.find(e => e.permission_flag === 'Sales')
      if(SalesTemp) {
        sale_nationwide = SalesTemp.children.find(e => e.permission_flag === 'sale_nationwide')?true:false //全国销量
        Report =  SalesTemp.children.find(e => e.permission_flag === 'Report')?true:false //销量报告
      }
    }
    if(temp.menu_title==='eGFR'){
      if(temp.children.find(e =>e.permission_flag === 'operation_btn')){
        console.log(111);
        egfr_operation_btn = true
      }
    }
    let menuData_item = menuData.find(e => e.permission_flag === temp.permission_flag)
    /* 统用菜单处理 */
    if(menuData_item){
      if(temp.children.length === 0||temp.menu_title==='eGFR') {  //无判断子页面全部添加
        // 路由添加
        if(menuData_item.parent){
          children.push(...menuData_item.route)
        }else{
          router.addRoute(...menuData_item.route);
        }
        // 页面添加
        menuList.push(menuData_item.menu)
      } else { //有权限判断
        // 路由添加
        menuData_item.route.forEach(e => {
          if(e.permission_flag) { //有控制
            if(temp.children.find(item => item.permission_flag === e.permission_flag)){
              children.push(...e.routeList)
            }
          }else { //无需控制
            children.push(...e.routeList)
          }
        })
        // 页面添加
        let childrenTemp = menuData_item.menu.children.filter(e => {
          return e.permission_flag?temp.children.find(item => item.permission_flag === e.permission_flag)?true:false:true
        })
        let menuTemp = JSON.parse(JSON.stringify(menuData_item.menu)); 
        menuTemp.children = childrenTemp
        menuList.push(menuTemp)
      }
    }
    
  })

  children.push(...HomeList)
  router.addRoute({
    path: '/',
    name: 'Home',
    component: Home,
    children,
  })
  /* 旧方法 */
  // var oldPage = JSON.parse(localStorage.getItem('oldPage'));
  // oldPage.name = oldPage.name==null||oldPage.name=='Login'?'SearchPage':oldPage.name
  // console.log(oldPage,"name")
  // router.push( oldPage )
  // router.push({ name:'Login' })
  /* 新方法 
    1、判断是否是登录页面，如果是，则进入登录页，每个人必有登陆页
    2、非登录页获取存储中要去的页面，如果name为空，就查找路由表，看曾经是否存在
    2.1、不存在，则动态设置该路由为404路由，并进入
    2.2、存在，跳转该路由
  */

  if(isLogin){ 
    router.push( {name: 'SearchPage'} )
    // router.replace({name: 'SearchPage'})
    return
  }
  var toPage = JSON.parse(sessionStorage.getItem('toPage'));
  if(toPage.name == null){
    let routerList = router.getRoutes()
    let tempPage = routerList.find( e=> e.path ===  toPage.path)
    if(tempPage){
      toPage.name = tempPage.name
    }else {
      router.addRoute({
        path: toPage.path,
        name: '404',
        component: () => import('../views/404.vue'),
      })
      toPage.name = "404"
    }
    // localStorage.setItem('oldPage', JSON.stringify({name,query:{id}}))
  }else if(toPage.name =='Login'){
    toPage = {name:'SearchPage'}
  }
  router.push( toPage )
  // router.replace(toPage)

}

